import { Injectable, computed, signal } from '@angular/core';
import { ProjectsSaleService } from '../../client';
@Injectable({
  providedIn: 'root',
})
export class SalesFacadeService extends ProjectsSaleService {
  _currentSaleId = signal('');

  setCurrentSaleId(id: string): void {
    console.log('SETTING CURRENT SALE ID:', id)
    this._currentSaleId.set(id);
  }

  public currentSaleId = computed(() => {
    return this._currentSaleId();
  });

  getCurrentSaleId(): string {
    return this._currentSaleId();
  }
}
